<template>
  <div>
    <div class="tab-content">
      <div
        class="tab-pane fade show active"
        role="tabpanel"
        aria-labelledby="nav-home-tab"
      >
        <div
          :class="{
            'container-quiz-mobile': this.isMobile,
            'container-quiz': !this.isMobile,
          }"
          v-if="!showResult"
        >
          <span class="text-item" v-if="!inPreview"
            >Questão {{ numberQuestion }}/{{
              quiz.questions ? quiz.questions.length : 0
            }}
          </span>
          <span class="text-item" v-else
            >Questão {{ indexItemPreview }}/{{
              quiz.questions ? quiz.questions.length : 0
            }}
          </span>

          <div class="mt-2">
            <p class="title-question">{{ question.text }}</p>
          </div>

          <div class="list-group">
            <div
              class="
                card card-body
                container-options
                text
                d-block
                row
                p-3
                spaceModuleCard
              "
              v-for="(answer, index) in question.answers"
              :key="index"
              @click="optionSelected(answer, question.my_answer)"
              :class="{
                disabled: selected === answer.id,
                is_correct: question.my_answer && answer.is_correct,
                no_is_correct:
                  question.my_answer &&
                  question.my_answer.id === answer.id &&
                  !answer.is_correct,
              }"
            >
              <div class="d-inline-block" v-if="!isMobile">
                <b-form-radio
                  @click="optionSelected(answer, question.my_answer)"
                  name="radio"
                  size="lg"
                  v-if="question.my_answer === null"
                  v-model="selected"
                  :value="answer.id"
                  :checked="answer.id === selected"
                ></b-form-radio>
                <b-form-radio
                  name="radio"
                  size="lg"
                  v-else
                  v-model="question.my_answer.id"
                  :value="answer.id"
                  :checked="answer.id === question.my_answer.id"
                  :disabled="true"
                ></b-form-radio>
              </div>
              <div class="d-inline-block text" :class="{ 'col-12': isMobile }">
                <span class="description-item">
                  {{ answer.text }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="{
            'container-quiz-mobile': this.isMobile,
            'container-quiz': !this.isMobile,
          }"
          v-else
        >
          <div class="card card-body quiz-finalizado">
            <div class="mb-3">
              <h6>
                Você acertou {{ result.success.length }} de um total de
                {{ this.quiz.questions.length }}
              </h6>
            </div>

            <div v-if="result.error.length" class="quiz-finalizado">
              <p class="text-quiz-finalizado text-center">
                <span style="color: red">X </span> Erradas
              </p>
              <div
                class=""
                :class="{
                  'container-text-result-mobile': this.isMobile,
                  'container-text-result': !this.isMobile,
                }"
              >
                <div v-for="(question, index) in result.error" :key="index">
                  <p class="p-0 mb-2">
                    <span style="font-size: 17px !important"
                      >{{ question.question_number }}:.</span
                    >
                    {{ question.text }}
                  </p>
                  <p class="ml-5">
                    {{ question.my_answer.text }}
                  </p>
                </div>
              </div>
            </div>
            <div v-if="result.success.length" class="quiz-finalizado">
              <p class="text-quiz-finalizado text-center">
                <img class="d-inline-block" src="@/assets/check.png" alt="" />
                Acertos
              </p>
              <div
                :class="{
                  'container-text-result-mobile': this.isMobile,
                  'container-text-result': !this.isMobile,
                }"
              >
                <div v-for="(question, index) in result.success" :key="index">
                  <p class="p-0 mb-2">
                    <span style="font-size: 17px !important"
                      >{{ question.question_number }}:.</span
                    >
                    {{ question.text }}
                  </p>
                  <p class="ml-5">
                    {{ question.my_answer.text }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-inline-block w-100 p-2">
        <button
          class="btn float-right ml-3"
          :class="{
            'btn-responder-mobile': this.isMobile,
            'btn-responder': !this.isMobile,
          }"
          @click="checkOption(question)"
          v-if="!showResult"
        >
          {{ nameBtnNext }}
        </button>

        <button
          class="btn float-right"
          :class="{
            'btn-responder-mobile': this.isMobile,
            'btn-responder': !this.isMobile,
          }"
          @click="backResultado(question)"
          :disabled="
            this.firstItem.id === question.id &&
            this.quiz.questions &&
            this.quiz.questions.length > 1
          "
        >
          Anterior
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CourseService from "@/services/resources/CourseService";

const serviceCourse = CourseService.build();

export default {
  name: "Quiz",
  props: ["quizData", "isMobile"],
  data() {
    return {
      quiz: this.quizData,
      question: [],
      respondida: [],
      selected: undefined,
      questionId: undefined,
      isCorrect: undefined,
      showResult: false,
      result: { error: [], success: [] },
      inPreview: false,
      itemObjPreview: {},
      indexItemPreview: 0,
      lastItem: {},
      firstItem: {},
      numberQuestion: 1,
      clearInterval: undefined,
    };
  },
  computed: {
    countItem: function () {
      if (this.respondida.length === 0) {
        return 1;
      } else if (this.respondida.length === this.quiz.questions.length) {
        return this.respondida.length;
      }
      return this.respondida.length + 1;
    },
    nameBtnNext() {
      if (this.showResult) {
        return "Resultado";
      }

      if (
        this.question.my_answer !== null &&
        this.question.id === this.lastItem.id
      ) {
        return "Resultado";
      }

      if (this.question.my_answer !== null) {
        return "Próximo";
      }

      if (this.countItem === this.quiz.questions.length) {
        return "Concluir";
      } else {
        return "Responder";
      }
    },
  },
  watch: {
    quizData: function () {
      this.quiz = this.quizData;
      this.reset();
    },
  },
  mounted() {
    this.clearInterval = setInterval(() => {
      if (this.quizData.id) {
        clearInterval(this.clearInterval);
        this.quiz = this.quizData;
        this.reset();
      }
    }, 1000);
  },
  methods: {
    reset() {
      this.question = [];
      this.respondida = [];
      this.selected = undefined;
      this.questionId = undefined;
      this.isCorrect = undefined;
      this.showResult = false;
      this.result = { error: [], success: [] };
      this.inPreview = false;
      this.itemObjPreview = {};
      this.indexItemPreview = 0;
      this.$emit("hideBtnConcluir");
      this.listData();
      this.next();
      this.numberQuestion =
        this.respondida.length === 0 ? 1 : this.respondida.length;
      let questionData = this.quiz.questions;
      this.firstItem = questionData[0];
      this.lastItem = questionData[questionData.length - 1];
    },
    backResultado(question) {
      this.quiz.questions.find((item) => {
        if (item.id === question.id) {
          if (this.showResult) {
            this.question = item;
          }
          this.showResult = false;
          return item;
        }
        this.question = item;
      });
      this.numberQuestion--;
      if (!this.numberQuestion) {
        this.numberQuestion = 1;
      }
    },
    returnItemSelected(item, indexItem) {
      this.itemObjPreview = item;
      this.respondida.find((res) => {
        if (res.id === item.id) {
          this.question = res;
          this.showResult = false;
          this.inPreview = true;
          this.indexItemPreview = indexItem + 1;
          this.selected = res.my_answer.id;
          return res;
        }
      });
    },
    async resulted() {
      if (this.countItem === this.quiz.questions.length) {
        let idCourse = this.$route.params.curso;
        let lessonAtual = this.$route.params.aula;
        let idModule = this.$route.params.modulo;

        let data = {
          id: `${idCourse}/module/${idModule}/lesson/${lessonAtual}/progress`,
          completed: true,
        };

        this.$root.$emit("loadOn");

        await serviceCourse
          .get(`${idCourse}/module/${idModule}/lesson/${lessonAtual}`)
          .then(async (response) => {
            this.$root.$emit("loadOff");
            let validate = true;
            let ultimo = [];
            this.result = { error: [], success: [] };
            response.questions.forEach((question, index) => {
              if (question.my_answer === null) {
                validate = false;
              } else if (question.my_answer && !question.my_answer.is_correct) {
                question.question_number = index + 1;
                this.result.error.push(question);
              } else if (question.my_answer && question.my_answer.is_correct) {
                question.question_number = index + 1;
                this.result.success.push(question);
              }
              ultimo = question;
            });

            this.showResult = validate;

            if (this.showResult) {
              this.question = ultimo;
            }

            if (this.showResult && !response.is_completed) {
              this.$root.$emit("loadOn");
              await serviceCourse.postID(data).then(() => {
                this.$emit("quizCompleted", true);
              });
            }
          })
          .finally(() => {
            this.$root.$emit("loadOff");
          });
      }
    },
    async next() {
      this.isCorrect = undefined;
      await this.resulted();
    },
    async checkOption(question) {
      if (this.question.my_answer !== null) {
        if (this.numberQuestion > this.quiz.questions.length) {
          this.numberQuestion = this.quiz.questions.length + 1;
        } else {
          this.numberQuestion++;
        }

        if (question.id === this.lastItem.id) {
          await this.next();
          this.question = this.lastItem;
          this.showResult = true;
        }
        this.quiz.questions.find((item, index) => {
          if (item.id === question.id) {
            if (this.quiz.questions.length === index + 1) {
              this.next();
              this.question = item;
              this.showResult = true;
              return item;
            }
            this.question = this.quiz.questions[index + 1];
            return item;
          }
        });
        return true;
      }

      if (!this.selected) {
        return;
      }

      let idCourse = this.$route.params.curso;
      let lessonAtual = this.$route.params.aula;
      let idModule = this.$route.params.modulo;

      serviceCourse
        .postID2({
          id: {
            id: `${idCourse}/module/${idModule}/lesson/${lessonAtual}/question/${this.questionId}/answer/student`,
          },
          data: {
            answer_id: this.selected,
            question_id: this.questionId,
          },
        })
        .then(async (resp) => {
          this.isCorrect = resp.is_correct;
          this.quiz.questions.find((item, index) => {
            if (item.id === this.questionId) {
              this.quiz.questions[index].my_answer = this.quiz.questions[
                index
              ].answers.find((option, indexOption) => {
                if (
                  this.quiz.questions[index].answers[indexOption].id ===
                  this.selected
                ) {
                  return option;
                }
              });
              return item;
            }
          });
          this.setRespondida();
          this.isCorrect = undefined;
        });
    },
    optionSelected(answer, respondida) {
      if (respondida) {
        return false;
      }
      this.isCorrect = false;
      this.selected = answer.id;
      this.questionId = answer.question_id;
    },
    setRespondida() {
      this.respondida = [];
      this.quiz.questions.forEach((item) => {
        if (item.my_answer) {
          this.respondida.push(item.id);
        }
      });
    },
    listData() {
      this.setRespondida();
      this.quiz.questions.some((item) => {
        if (
          !this.respondida.find((id) => id === item.id) &&
          item.my_answer === null
        ) {
          this.question = item;
          return item;
        }
      });
    },
  },
};
</script>

<style>
.playerMobile {
  /*height: inherit !important;*/
  display: inline-block !important;
}

.list-group > fieldset > div > div > label {
  margin: 5px 0 !important;
  padding: 10px;
  background-color: #fff;
  color: black;
  text-align: left;
}

.list-group > fieldset > div > div > label:hover {
  background-color: #fff;
  color: black;
}
.list-group > fieldset > div > div > .active {
  background: rgba(33, 51, 210, 0.05);
  border: 1px solid #2133d2;
  box-sizing: border-box;
  border-radius: 5px;
}

.container-options.is_correct {
  border: 1px solid #00e4a0 !important;
  background: rgba(0, 228, 160, 0.05);
}
.container-options.no_is_correct {
  border: 1px solid red !important;
  background: rgba(33, 51, 210, 0.05);
}
</style>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

.spaceModuleCard {
  width: 100%;
  background: var(--backgroundcolor);
  border: 0.5px solid var(--bordercolor);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px 15px;
  margin-bottom: 20px;
  cursor: pointer;
}
/**/
.spaceModuleCard > .text > span {
  color: var(--fontcolor) !important;
}

.quiz-finalizado {
  background-color: var(--backgroundcolor) !important;
  color: var(--fontcolor) !important;
}
.quiz-finalizado > div {
  color: var(--fontcolor) !important;
}
.quiz-finalizado > p {
  color: var(--fontcolor) !important;
}

.container-text-result > p {
  cursor: pointer;
}

.quiz-finalizado {
  align-items: center;
}

.container-text-result-mobile,
.container-text-result {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #000000;
}

.container-text-result {
  width: 100%;
  margin: 0 auto;
}

.text-quiz-finalizado {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.btn-responder-mobile,
.btn-responder {
  background: #2133d2;
  border-radius: 4px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.btn-responder {
  width: 169px;
  height: 50px;
}

.text-item {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #2133d2;
}

.title-question {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--fontcolor) !important;
}

.description-item {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #000000;
}

.container-quiz-mobile {
  display: inline-block;
  /*height:30vh;*/
  /*overflow: auto;*/
}

.list-group > .disabled {
  cursor: not-allowed;
  background: rgba(33, 51, 210, 0.05);
  border: 1px solid #2133d2;
  box-sizing: border-box;
  border-radius: 5px;
}

.container-options {
  cursor: pointer;
  margin: 5px 0;
  padding: 10px;
}
.text {
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
}
</style>
