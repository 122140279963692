var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"tab-content"},[_c('div',{staticClass:"tab-pane fade show active",attrs:{"role":"tabpanel","aria-labelledby":"nav-home-tab"}},[(!_vm.showResult)?_c('div',{class:{
          'container-quiz-mobile': this.isMobile,
          'container-quiz': !this.isMobile,
        }},[(!_vm.inPreview)?_c('span',{staticClass:"text-item"},[_vm._v("Questão "+_vm._s(_vm.numberQuestion)+"/"+_vm._s(_vm.quiz.questions ? _vm.quiz.questions.length : 0)+" ")]):_c('span',{staticClass:"text-item"},[_vm._v("Questão "+_vm._s(_vm.indexItemPreview)+"/"+_vm._s(_vm.quiz.questions ? _vm.quiz.questions.length : 0)+" ")]),_c('div',{staticClass:"mt-2"},[_c('p',{staticClass:"title-question"},[_vm._v(_vm._s(_vm.question.text))])]),_c('div',{staticClass:"list-group"},_vm._l((_vm.question.answers),function(answer,index){return _c('div',{key:index,staticClass:"card card-body container-options text d-block row p-3 spaceModuleCard",class:{
              disabled: _vm.selected === answer.id,
              is_correct: _vm.question.my_answer && answer.is_correct,
              no_is_correct:
                _vm.question.my_answer &&
                _vm.question.my_answer.id === answer.id &&
                !answer.is_correct,
            },on:{"click":function($event){return _vm.optionSelected(answer, _vm.question.my_answer)}}},[(!_vm.isMobile)?_c('div',{staticClass:"d-inline-block"},[(_vm.question.my_answer === null)?_c('b-form-radio',{attrs:{"name":"radio","size":"lg","value":answer.id,"checked":answer.id === _vm.selected},on:{"click":function($event){return _vm.optionSelected(answer, _vm.question.my_answer)}},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_c('b-form-radio',{attrs:{"name":"radio","size":"lg","value":answer.id,"checked":answer.id === _vm.question.my_answer.id,"disabled":true},model:{value:(_vm.question.my_answer.id),callback:function ($$v) {_vm.$set(_vm.question.my_answer, "id", $$v)},expression:"question.my_answer.id"}})],1):_vm._e(),_c('div',{staticClass:"d-inline-block text",class:{ 'col-12': _vm.isMobile }},[_c('span',{staticClass:"description-item"},[_vm._v(" "+_vm._s(answer.text)+" ")])])])}),0)]):_c('div',{class:{
          'container-quiz-mobile': this.isMobile,
          'container-quiz': !this.isMobile,
        }},[_c('div',{staticClass:"card card-body quiz-finalizado"},[_c('div',{staticClass:"mb-3"},[_c('h6',[_vm._v(" Você acertou "+_vm._s(_vm.result.success.length)+" de um total de "+_vm._s(this.quiz.questions.length)+" ")])]),(_vm.result.error.length)?_c('div',{staticClass:"quiz-finalizado"},[_vm._m(0),_c('div',{class:{
                'container-text-result-mobile': this.isMobile,
                'container-text-result': !this.isMobile,
              }},_vm._l((_vm.result.error),function(question,index){return _c('div',{key:index},[_c('p',{staticClass:"p-0 mb-2"},[_c('span',{staticStyle:{"font-size":"17px !important"}},[_vm._v(_vm._s(question.question_number)+":.")]),_vm._v(" "+_vm._s(question.text)+" ")]),_c('p',{staticClass:"ml-5"},[_vm._v(" "+_vm._s(question.my_answer.text)+" ")])])}),0)]):_vm._e(),(_vm.result.success.length)?_c('div',{staticClass:"quiz-finalizado"},[_vm._m(1),_c('div',{class:{
                'container-text-result-mobile': this.isMobile,
                'container-text-result': !this.isMobile,
              }},_vm._l((_vm.result.success),function(question,index){return _c('div',{key:index},[_c('p',{staticClass:"p-0 mb-2"},[_c('span',{staticStyle:{"font-size":"17px !important"}},[_vm._v(_vm._s(question.question_number)+":.")]),_vm._v(" "+_vm._s(question.text)+" ")]),_c('p',{staticClass:"ml-5"},[_vm._v(" "+_vm._s(question.my_answer.text)+" ")])])}),0)]):_vm._e()])])]),_c('div',{staticClass:"d-inline-block w-100 p-2"},[(!_vm.showResult)?_c('button',{staticClass:"btn float-right ml-3",class:{
          'btn-responder-mobile': this.isMobile,
          'btn-responder': !this.isMobile,
        },on:{"click":function($event){return _vm.checkOption(_vm.question)}}},[_vm._v(" "+_vm._s(_vm.nameBtnNext)+" ")]):_vm._e(),_c('button',{staticClass:"btn float-right",class:{
          'btn-responder-mobile': this.isMobile,
          'btn-responder': !this.isMobile,
        },attrs:{"disabled":this.firstItem.id === _vm.question.id &&
          this.quiz.questions &&
          this.quiz.questions.length > 1},on:{"click":function($event){return _vm.backResultado(_vm.question)}}},[_vm._v(" Anterior ")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-quiz-finalizado text-center"},[_c('span',{staticStyle:{"color":"red"}},[_vm._v("X ")]),_vm._v(" Erradas ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-quiz-finalizado text-center"},[_c('img',{staticClass:"d-inline-block",attrs:{"src":require("@/assets/check.png"),"alt":""}}),_vm._v(" Acertos ")])
}]

export { render, staticRenderFns }